import { OrgId, OrgInvoice, OrgInvoiceParent, PaymentMethod, PaymentMethodSnapshot, PaymentMethodType, PlayerBundleId } from "..";
import { AccountId } from "./Account.schema";
import { OrgInvoiceId } from "./OrgInvoice.schema";

export type OrgPaymentId = string;
type OrgPaymentBase = {
  id: OrgPaymentId;
  orgId: OrgId;
  amountCents: number;
  //This amount is IN ADDITION to amountCents
  lateFeeAmountCents?: number;
  //This amount is IN ADDITION to amountCents
  processingFeeAmountCents?: number;
  createdAtMS: number;
};

export type OrgPaymentCardDetails = {
  cardBrand: string;
  last4: string;
  expMonth: number;
  expYear: number;
};

export enum OrgPaymentType {
  invoiceDefault = "invoiceDefault",
  invoiceFailedPayment = "invoiceFailedPayment",
  invoiceFailedECheckPayment = "invoiceFailedECheckPayment",
  invoiceCredit = "invoiceCredit",
  openOrgEventDefault = "openOrgEventDefault"
}

export type OrgPaymentInvoiceDefault = OrgPaymentBase & {
  type: OrgPaymentType.invoiceDefault;
  status: "succeeded";
  playerBundleId: PlayerBundleId;
  invoiceId: OrgInvoiceId;
  invoiceGroupId: OrgInvoiceId;
  accountId: AccountId;
  isSettledECheck?: boolean;
  hasPendingChargeback?: boolean;
  nmiPaymentResponseInfo?: NMIPaymentResponseInfo__Success; //Needs to be optional b/c of 0 cent charges (such as from a discount, etc)
  paymentMethodSnapshot?: PaymentMethodSnapshot;
};

export type OrgPaymentInvoiceCredit = OrgPaymentBase & {
  type: OrgPaymentType.invoiceCredit;
  playerBundleId: PlayerBundleId;
  invoiceId: OrgInvoiceId;
  invoiceGroupId: OrgInvoiceId;
  status: "succeeded";
  note: string;
  groupingId: string; //Used when admins issue a credit that needs to seamlessly apply across multiple payments.
  appliedByAccountId: AccountId; // usually a club admin
};

export type OrgPaymentInvoiceFailed = OrgPaymentBase & {
  type: OrgPaymentType.invoiceFailedPayment;
  playerBundleId: PlayerBundleId;
  invoiceId: OrgInvoiceId;
  invoiceGroupId: OrgInvoiceId;
  status: "failed";
  accountId: AccountId;
  nmiPaymentResponseInfo: NMIPaymentResponseInfo__Failure;
  paymentMethodSnapshot?: PaymentMethodSnapshot;
};

export type OrgPaymentInvoiceFailedECheck = OrgPaymentBase & {
  type: OrgPaymentType.invoiceFailedECheckPayment;
  playerBundleId: PlayerBundleId;
  invoiceId: OrgInvoiceId;
  invoiceGroupId: OrgInvoiceId;
  status: "failed";
  accountId: AccountId;
  nmiPaymentResponseInfo: NMIPaymentResponseInfo__Success; //The original response from when the payment was first made.
  nmiReturnResponseInfo: {
    transaction_id: string;
    action: {
      amount: string;
      action_type: string;
      date: string;
      success: string;
      ip_address: string;
      source: string;
      api_method: string;
      username: string;
      response_text: string;
      response_code: string;
      processor_response_text: string;
      tap_to_mobile: boolean;
      processor_response_code: string;
      device_license_number: string;
      device_nickname: string;
    };
    check: {
      check_account: null; //NMI doesn't redact enough info on the account # IMO, so set this to null to ensure parts aren't leaked
      check_aba: string;
      check_name: string;
      account_holder_type: string;
      account_type: string;
      sec_code: string;
    };
  };
  paymentMethodSnapshot?: PaymentMethodSnapshot;
};

export type OrgPaymentOpenOrgEvent = OrgPaymentBase & {
  type: OrgPaymentType.openOrgEventDefault;
  status: "succeeded";
  nmiPaymentResponseInfo?: NMIPaymentResponseInfo;
};

export type OrgPaymentInvoice =
  | OrgPaymentInvoiceDefault
  | OrgPaymentInvoiceCredit
  | OrgPaymentInvoiceFailed
  | OrgPaymentInvoiceFailedECheck;
export type OrgPayment = OrgPaymentInvoice | OrgPaymentOpenOrgEvent;

export enum OrgPaymentInvoiceCreditApplicationMethod {
  equal = "equal",
  beginning = "beginning",
  end = "end"
}

export type OrgPaymentInvoiceWithOrgInvoiceAndParentOrgInvoiceWhereApplicable = {
  orgPayment: OrgPaymentInvoice;
  orgInvoice: OrgInvoice;
  parentOrgInvoice?: OrgInvoiceParent;
};

export const NMIResponseCodes = {
  100: "Transaction was approved.",
  200: "Transaction was declined by processor.",
  201: "Do not honor.",
  202: "Insufficient funds.",
  203: "Over limit.",
  204: "Transaction not allowed.",
  220: "Incorrect payment information.",
  221: "No such card issuer.",
  222: "No card number on file with issuer.",
  223: "Expired card.",
  224: "Invalid expiration date.",
  225: "Invalid card security code.",
  226: "Invalid PIN.",
  240: "Call issuer for further information.",
  250: "Pick up card.",
  251: "Lost card.",
  252: "Stolen card.",
  253: "Fraudulent card.",
  260: "Declined with further instructions available. (See response text)",
  261: "Declined-Stop all recurring payments.",
  262: "Declined-Stop this recurring program.",
  263: "Declined-Update cardholder data available.",
  264: "Declined-Retry in a few days.",
  300: "Transaction was rejected by gateway.",
  400: "Transaction error returned by processor.",
  410: "Invalid merchant configuration.",
  411: "Merchant account is inactive.",
  420: "Communication error.",
  421: "Communication error with issuer.",
  430: "Duplicate transaction at processor.",
  440: "Processor format error.",
  441: "Invalid transaction information.",
  460: "Processor feature not available.",
  461: "Unsupported card type."
};

export function isKeyOfNMIResponseCodes(key: any): key is keyof typeof NMIResponseCodes {
  return key.toString() in NMIResponseCodes;
}

export type NMIPaymentResponseInfoBase = {
  response: number;
  responseText: string;
  responseCode?: number;
  responseCodeText?: string;
};
export type NMIPaymentResponseInfo__Success = NMIPaymentResponseInfoBase & {
  status: "success";
  transactionId: string;
};

export type NMIPaymentResponseInfo__Failure = NMIPaymentResponseInfoBase & { status: "failure"; transactionId?: string };

export type NMIPaymentResponseInfo = NMIPaymentResponseInfo__Success | NMIPaymentResponseInfo__Failure;
