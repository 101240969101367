import { ORG_FUNDRAISER_VISIBILITY_STATUS, TEAM_SPORT } from "..";
import { TEAM_TYPES } from "../constants";
import { AccountId } from "./Account.schema";
import { ConversationId } from "./Conversation.schema";
import { LicenseId } from "./License.schema";

export type OrgId = string;

export enum ORG_PERMISSIONS {
  manageOrgAccounts = "manageOrgAccounts",
  manageTeams = "manageTeams",
  manageOrgChat = "manageOrgChat",
  manageFinances = "manageFinances",
  viewFinances = "viewFinances"
}

export interface Org__Account {
  title: string;
  exists: true;
  permissions: { [id in ORG_PERMISSIONS]?: boolean };
}

export enum OrgFeatures {
  stats = "stats",
  orgDirectory = "orgDirectory",
  alphaNumericTryoutIdentifiers = "alphaNumericTryoutIdentifiers",
  payments = "payments"
}

export interface Org__Shell {
  name: string;
  shortName: string;
  slug: string;
  country: string;
  regionCode: string;
  timezone?: string;
  province: string;
  defaultTeamType: TEAM_TYPES;
  defaultSportType: TEAM_SPORT;
}

export interface Org {
  id: OrgId;
  createdAtMS: number;
  name: string;
  shortName: string;
  slug: string;
  country?: string;
  province?: string;
  regionCode?: string;
  timezone?: string;
  linkedLicenseId?: LicenseId;
  enabledFeatures?: { [feature in OrgFeatures]?: true };
  logoUri?: string;
  defaultSportType: TEAM_SPORT;
  defaultTeamType: TEAM_TYPES;
  accounts: Record<AccountId, Org__Account | undefined>;
  numTeamSponsorsAllowed?: number;
  yearlyContractValue?: number;
  stripeAccountId?: string;
  orgQuestions?: { archived?: true; question: string; teamTagIds?: Record<string, true>; birthYears?: Record<string, true> }[];
}
