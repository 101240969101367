import {
  OrgRegistrationPackageId,
  OrgInvoiceId,
  OrgSeasonId,
  PlayerBundleId,
  TeamId,
  AccountId,
  OrgRegistrationPackage
} from "..";
import { OrgId } from "./Org.schema";
import { OrgRegistrationAnswerId } from "./OrgRegistrationAnswer.schema";

export type OrgRegistrationId = string;

export type OrgRegistration = {
  createdAtMS: number;
  id: OrgRegistrationId;
  playerBundleId: PlayerBundleId;
  orgId: OrgId;
  orgRegistrationPackageId: OrgRegistrationPackageId;
  orgSeasonId: OrgSeasonId;
  orgInvoiceId: OrgInvoiceId;
  answerIds?: Record<OrgRegistrationAnswerId, true>;
  affiliatedOrgTeamsSnapshot: Record<TeamId, { name: string }>;
  orgNameSnapshot: string;
  completedAllAdditionalStepsAtMS: number;
  relevantPackageInfoSnapshot: Pick<OrgRegistrationPackage, "amountCents" | "lineItems">;
};

export enum OrgRegistrationStatus {
  bad = "bad",
  registered = "registered",
  unregistered = "unregistered",
  "no-registration-needed" = "no-registration-needed",
  incomplete = "incomplete",
  "team-not-ready" = "team-not-ready"
}

export enum OrgRegistrationPaymentStatus {
  "not-started" = "not-started",
  "in-progress" = "in-progress",
  "completed" = "completed"
}
export type OrgRegistrationTableData = {
  registrationDateMS?: number;
  status: OrgRegistrationStatus;
  playerBundleId: PlayerBundleId;
  firstName: string;
  lastName: string;
  selfAthleteAccountId?: AccountId;
  packageAmountCents?: number;
  packageName?: string;
  orgRegistration?: OrgRegistration;
};
