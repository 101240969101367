import { OrgId } from "./Org.schema";
import { OrgRegistrationPackageId } from "./OrgRegistrationPackage.schema";
import { OrgPaymentPlanId } from "./OrgPaymentPlan.schema";
import { OrgPaymentInvoice } from "./OrgPayment.schema";
import { PlayerId } from "./Player.schema";
import { PlayerBundleId } from "./PlayerBundle.schema";
import { AccountId, OrgCouponId, OrgSeasonId } from "..";

export type OrgInvoiceId = string;
type OrgInvoiceBase = {
  id: OrgInvoiceId;
  invoiceGroupId: OrgInvoiceId; //If parent invoice, this will be equal to the invoice id. Otherwise, if the invoice is a child, it is the parent invoice id.
  memo: string;
  orgId: OrgId;
  playerBundleId: PlayerBundleId;
  createdAtMS: number;
  dueDateMS: number;
  amountDueCents: number;
  // Will be set differently depending on the type of invoice. Can come from registration package or it's defined on the manul invoice
  lateFeeCentsToBeIssuedIfLate: number;
  // Derived from amountCents on OrgPayment for THIS specific invoice, including credits and actual payments.
  // DOES NOT include late fees or processing fees.
  // DOES NOT include children payments on any children invoices the invoice may have
  // DOES include all successful payments, both credits and actual money.
  derivedTotalAmountPaidCentsBeforeAllFees: number;
  thisInvoicePaidInFullDateMS: number;
};

type OrgInvoiceParentBase = {
  //Equals amountDueCents except if children invoices have been created
  derivedTotalAmountDueCentsIncludingChildrenInvoices: number;
  // Includes credits and actual payments. Does NOT include fees. Equals parentInvoice.derivedTotalAmountPaidCentsBeforeAllFees + allChildrenInvoices.derivedTotalAmountPaidCentsBeforeAllFees
  derivedTotalAmountPaidCentsIncludingChildrenInvoices: number;
  appliedCouponCodeDetails?: {
    orgCouponId: OrgCouponId;
    discountAmountCents: number;
  };
};

type OrgInvoicePaymentPlanInstallmentBase = {
  autoChargeDateMS: number;
  orgPaymentPlanId: OrgPaymentPlanId;
  parentOrgInvoiceId: OrgInvoiceId;
};

export enum OrgInvoiceTypes {
  manual = "manual",
  manualPaymentPlanInstallment = "manual-payment-plan-installment",
  registration = "registration",
  registrationPaymentPlanInstallment = "registration-payment-plan-installment"
}

export type OrgInvoice__Manual = OrgInvoiceBase &
  OrgInvoiceParentBase & {
    type: OrgInvoiceTypes.manual;
    allowedPaymentPlanIds?: Record<OrgPaymentPlanId, true>;
    assignedByAccountId: AccountId;
  };

export type OrgInvoice__Registration = OrgInvoiceBase &
  OrgInvoiceParentBase & {
    type: OrgInvoiceTypes.registration;
    orgSeasonId: OrgSeasonId;
    orgRegistrationPackageId: OrgRegistrationPackageId;
  };

export type OrgInvoice__ManualPaymentPlanInstallment = OrgInvoiceBase &
  OrgInvoicePaymentPlanInstallmentBase & {
    type: OrgInvoiceTypes.manualPaymentPlanInstallment;
    accountIdScheduledToPay: AccountId;
    previousFailedPaymentAttemptMS?: number;
    failedPaymentScheduledRetryMS?: number;
    numberOfFailedPaymentAttempts?: number;
  };

export type OrgInvoice__RegistrationPaymentPlanInstallment = OrgInvoiceBase &
  OrgInvoicePaymentPlanInstallmentBase & {
    type: OrgInvoiceTypes.registrationPaymentPlanInstallment;
    orgRegistrationPackageId: OrgRegistrationPackageId;
    orgSeasonId: OrgSeasonId;
    accountIdScheduledToPay: AccountId;
    previousFailedPaymentAttemptMS?: number;
    failedPaymentScheduledRetryMS?: number;
    numberOfFailedPaymentAttempts?: number;
  };

export type OrgInvoiceParent = OrgInvoice__Registration | OrgInvoice__Manual;

export type OrgInvoiceChild = OrgInvoice__RegistrationPaymentPlanInstallment | OrgInvoice__ManualPaymentPlanInstallment;

export type OrgInvoice = OrgInvoiceParent | OrgInvoiceChild;

export type OrgInvoiceParentWithChildAndPaymentData = {
  parentOrgInvoice: OrgInvoiceParent;
  childrenOrgInvoices: OrgInvoiceChild[];
  orgPayments: OrgPaymentInvoice[];
};

export type ParentInvoiceData = {
  due_date_ms: string;
  created_at_ms: string;
  id: string;
  org_id: string;
  total_invoiced: string;
  total_paid: string;
  total_balance_remaining: string;
  memo: string;
  num_invoices: string;
  num_paid_invoices: string;
  status: OverallInvoiceStatus;
  player_name: string;
  item: OrgInvoice__Manual;
};

export enum OverallInvoiceStatus {
  paid = "paid",
  latePaymentInstallment = "latePaymentInstallment",
  invoiceFailedPayment = "invoiceFailedPayment",
  inProgress = "inProgress",
  late = "late",
  outstanding = "outstanding"
}
